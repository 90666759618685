.site-header {
    border-bottom: 1px solid;
    border-color: $grey1;
    overflow: hidden;
    padding-bottom: calcRem(20px);
    padding-top: calcRem(20px);

    @include tablet-landscape-up {
        padding-bottom: 0.5rem;
        padding-top: 2rem;
    }

    .cell {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.site-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.site-title,
.site-subtitle,
.site-info {
    margin-bottom: 0;

    a {
        color: $black;
    }
}

.site-title {
    svg {
        display: block;
    }
}

.site-subtitle {
    font-size: calcRem(24px);
}

.site-info {
    font-size: calcRem(14px);
}

.header-img {
    @include tablet-landscape-up {
        float: left;
    }
}

.header-main {
    margin-top: calcRem(12px);

    .header-img + & {
        @include tablet-landscape-up {
            margin-top: 0;
            overflow: hidden;
            padding-top: calcRem(16px);
            padding-bottom: 0.25rem;
        }
    }
}
