.tabs {
  font-size: 0;
}

.tabs__heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: calcRem(1px) $grey1 solid;
}

.tab-mark {
  border: none;
  border-bottom: calcRem(4px) transparent solid;
  cursor: pointer;
  display: inline-block;
  font-family: $font-text-bold;
  font-size: calcRem(18px);
  padding: calcRem(16px) 0;
  vertical-align: bottom;

  span {
    display: block;
  }

  [type="radio"]:checked + &,
  &.active {
    // background-color: $white;
    // border-bottom: calcRem(4px) $red solid;
  }
  
  &.inactive {
    color: $grey2;
  }

  &.disabled {
    color: $grey2;
    pointer-events: none;
  }

  & + & {
    margin-left: calcRem(36px);
  }
}

.tab-title {
  font-family: $font-text-bold;
}

.tab-info {
  color: $grey1;
  font-size: calcRem(11px);
}

.tab-content {
  clear: both;
  font-size: calcRem(16px);
  padding: calcRem(20px);
}

.tab-content--flush-lr {
  padding-left: 0;
  padding-right: 0;
}

.tab-body {
  display: none;

  &.active {
    display: block;
  }
}

.tabs__controls {
  position: relative;
}

.tabs__control-switch {
  padding-right: calcRem(27px);
}

.tabs__control-switch > * {
  display: inline-block;
  font-size: 14px;
  color: $grey2;
  vertical-align: middle;
}

.tabs__controls-highlight {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(0);
  width: calcRem(118px);
  height: calcRem(4px);
  border-bottom: calcRem(4px) $red solid;
  transition: 
    width ease 250ms,
    transform ease 250ms;
}
