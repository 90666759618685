.revealers {}

.revealer {
  display: block;
}

.revealer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.revealer-open {
  cursor: pointer;
  font-family: $font-text-bold;
  font-size: calcRem(20px);
  margin-bottom: 0;
  padding-bottom: calcRem(16px);
  padding-top: calcRem(22px);
  position: relative;
  line-height: unset;
}

.revealer-icon {
  position: relative;
  height: calcRem(20px);
  width: calcRem(20px);
  margin-top: 0.25rem;

  svg {
    display: block;
    height: inherit;
    width: inherit;
  }
}

.revealed-icon {
  transform: rotate(180deg);
}

.revealer-body {
  padding-bottom: calcRem(16px);

  :last-child {
    margin-bottom: 0;
  }
}