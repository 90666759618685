.subhead-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3rem;

    .button--add-asset {
        font-size: 0.875rem;
    }

    .subhead--assets {
        margin-bottom: 0;
    }

    .subhead-title-container {
        display: flex;
        align-items: center;
    }
}

.asset-edit__container {
    display: flex;
    min-height: 50vh;

    .asset-edit__column {
        width: 50%;
    }

    .asset-edit__column--right {
        padding-left: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.thumbnail__container {
    display: flex;

    .thumbnail__details {
        padding: 0 0 0 1rem;
        font-size: 0.75rem;
    }
}

.form-controls__container {
    display: flex;
    justify-content: flex-end;
}
