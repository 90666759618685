//this is important
.inline-label ~ .selectize-control {
  display: inline-block;
  vertical-align: middle;
  min-width: calcRem(200px);
  margin-left: calcRem(6px);
  max-height: calcRem(40px);
}

//the input
.selectize-input,
.selectize-control.single .selectize-input {
  background-color: $white;
  background-image: url('../images/icon-caret-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) center;
  border: 1px solid;
  border-color: $grey1;
  border-bottom-color: black;
  border-radius: 0;
  box-shadow: none;
  color: $black;
  font-size: calcRem(14px);
  line-height: 1.45;
  padding: calcRem(9px) calcRem(13px);

  //when the input is active
  &.input-active,
  &.not-full {
    box-shadow: none;
    background-image: url('../images/icon-caret-up.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
  }

  //when the dropdown next to input is active
  &.dropdown-active {
    border-color: $black;
    border-radius: 0;
  }

  .multi & {
    background-image: none;
  }

  //a validation error
  &.has-error,
  &.has-error:focus,
  .has-error &,
  .has-error &:focus {
    border-bottom: 3px solid;
    border-color: $orange;
  }
}

//the dropdown icon
.selectize-control.single .selectize-input:after {
  // border: none;
  display: none;
}

//the dropdown with results
//the dropdown with results
.selectize-dropdown,
.selectize-control.single .selectize-dropdown {
  border: 1px solid;
  border-color: $black;
  border-radius: 0;
  box-shadow: none;
}

//the items within the dropdown
.option[data-selectable] {
  // border-bottom: 1px solid;
  // border-top: 1px solid;
  // border-bottom-color: transparent;
  // border-top-color: $grey1;
  font-size: calcRem(14px);
  line-height: 1.45;
  overflow: hidden;

  &:hover,
  &:first-of-type {
    background-color: $white;
    color: $black;
  }

  &:first-of-type {
    border-top-color: transparent;
  }

  //when dropdown is active
  //we find any items withint dropdown
  .dropdown-active ~ .selectize-dropdown & {
    background-color: $white;
    color: $black;
  }
}

//the mutli selector item container
.selectize-control.multi .selectize-input.has-items {
  padding: calcRem(9px) calcRem(13px);
}

//the multi selector items
//and active state
.selectize-control.multi .selectize-input [data-value],
.selectize-control.multi .selectize-input [data-value].active {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: $black;
  display: inline;
  margin: 0;
  padding-bottom: 0;
  padding-left: calcRem(14px);
  padding-right: 0 !important; //default uses important too
  padding-top: 0;
  text-shadow: none;

  &:after {
    content: '';
    margin-right: calcRem(16px);
  }
}

// //when multi selector items are next to eachother
// .selectize-control.multi .selectize-input [data-value] + [data-value] {
//   margin-left: calcRem(18px);
// }

.selectize-control.plugin-remove_button [data-value] .remove {
  border: none;
  font-size: 100%;
  left: 0;
  line-height: 1.45;
  padding: 0;
  right: auto;
  width: auto;

  &:hover {
    background-color: transparent;
  }
}

.selectize-dropdown-content {
  max-height: calcRem(280px);
}

//crazy border shenanigans
.selectize-dropdown-content .option {
  // border: 1px solid;
  // border-color: white;
  border-top: 1px solid;
  border-top-color: $grey1;
  border-left: 4px solid;
  border-left-color: transparent;

  padding-bottom: (9px);
  padding-top: (9px);
  padding-right: (13px);
  padding-left: (9px);

  &:hover {
    border-left-color: $black;
  }

  &.is-selected,
  &.is-selected.active,
  &.is-selected.selected.active {
    border-left-color: $red;
  }
}