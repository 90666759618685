input,
textarea,
select {
  font-family: $font-text-reg;
  font-size: 1rem;
  line-height: 1.45;
  font-weight: 700;
}

input::placeholder {
  color: #6F7171
}

textarea {
  resize: none;
}

//Chrome/Opera/Safari
::-webkit-input-placeholder {
  color: $black;
}
//Firefox 19+
::-moz-placeholder {
  color: $black;
}
//Firefox 18-
:-moz-placeholder {
  color: $black;
}
//IE 10+
:-ms-input-placeholder {
  color: $black;
}

.form {
  & > div ~ div {
    margin-top: calcRem(16px);
  }

  & > div ~ div + .form-controls {
    margin-top: calcRem(32px);
  }
}

form-control, form-control-edit, asset-form-control {
  display: block;
}

form-control-edit, asset-form-control {
  position: relative;
  &.locked {
    color: $grey1;
    opacity: 0.5;
    pointer-events: none;
    textarea, input, select {
      color: $grey2;
      background-color: $grey1;
      border-color: $grey1;
    }
    .custom-checkbox, .custom-radio {
      background: $grey1;
      border-color: $grey1;
    }
    .custom-checkbox-label, .custom-radio-label {
      color: $grey1;
    }
    .custom-radio-icon, .custom-checkbox-icon {
      background-color: $grey1;
      fill: $grey1;
    }
    /*&:after {
      content: '';
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      background: rgba(0, 0, 0, 0.3);
      z-index: 100;
    }*/
  }
}

.stable-form {
  @extend .form;
  max-width: calcRem(800px);
  
  & > div ~ div, & > form-control ~ form-control,
  & > div ~ div, & > form-control-edit ~ form-control-edit,
  & > div ~ div, & > form-control ~ .form-controls {
    margin-top: calcRem(40px);
  }

  & > div ~ div + .form-controls,
  & > form-control ~ form-control + .form-controls,
  & > form-control-edit ~ form-control-edit + .form-controls {
    margin-top: calcRem(80px);
  }
  + .stable-form {
    margin-top: 2rem;
  }
}

.form-subtitle {
  margin-bottom: calcRem(8px);
}

.form-description {
  color: $grey2;
  margin-bottom: 0;

  .custom-radio-label & {
    margin-top: calcRem(2px);
  }
}

.form-label {
  color: $grey2;
  font-size: calcRem(12px);
  margin-bottom: calcRem(4px);

  &.inline-label {
    margin-bottom: 0;
  }

  .stable-form & {
    color: $black;
  }
}

.block-label {
  display: block;

  & + & {
    margin-top: calcRem(10px);
  }
}

.block-label--row {
  margin-right: 1rem;

  & + & {
    margin-top: 0;
  }
}

.inline-label {
  display: inline-block;
  vertical-align: middle;
}

.required {
  color: $orange;
}

.field-wrap {
  position: relative;
}

.small-field-wrap {
  @extend .field-wrap;
  max-width: 300px;
}

.form-field {
  border: 1px solid $grey1;
  border-bottom-color: $black;
  cursor: pointer;
  padding: calcRem(9px) calcRem(13px);
  width: 100%;

  &.has-error,
  &.has-error:focus {
    border-color: $orange;
    border-bottom-width: 3px;
  }

  p {
    margin-bottom: 0;
  }

  + .form-select-list {
    border-bottom: 1px solid black;
    display: none;
    height: auto;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;

    .form-select {
      border-top: none;
      cursor: pointer;
      width: 100%;
      background: white;
      right: 0;
      z-index: 10;
      display: flex;
      align-items: center;
    }
  }
  &.form-field-open {
    + .form-select-list {
      display: block;
    }
  }
}

.upload-field {
  &.has-error {
    color: $orange;
    label {
      border-color: $orange;
      color: $orange;
    }
  }
}

.chosen-assets {
  margin-top: calcRem(16px);
}

.chosen-asset-single {
  border: 1px solid #d8dada;
  border-bottom-color: #000000;
  cursor: pointer;
  padding: 0.5625rem 0.8125rem;
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin: 0 auto;
  }
}

.search-wrap {
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.search-result-num {
  color: $grey2;
  display: inline-block;
  font-size: 0.8em;
  padding-left: 0.7em;
  padding-top: 0.7em;
}

.form-select-section {
  margin-left: 1rem;
  overflow: hidden;
}

.form-field:focus {
  border-color: $black;
  outline: none;
}

.form-error-message {
  font-size: calcRem(9px);
}

.search-label {
  @include centerer(false,true);
  cursor: pointer;
  margin-bottom: 0;
  left: 1rem;

  svg {
    display: block;
  }
}

.search-field {
  border: 1px solid black;
  padding: 0.5rem 2.5rem;
  border-radius: 30px;
  width: 100%;
  font-family: $font-disp-bold;

  &::-ms-clear {
    display: none;
  }
}

.form-controls {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.stable-form-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stable-form-buttons {
  margin-top: calcRem(40px);
  
  project-action {
    margin-left: calcRem(4px);
    margin-right: calcRem(4px);
  }
  project-action:first-of-type {
    margin-right: 0;
  }
  project-action:last-of-type {
    margin-left: 0;
  }
}

.stable-form-links {
  .link + .link {
    margin-left: calcRem(16px);
  }
}

.form-control-info {
    margin-bottom: 0;

  .button + & {
    margin-left: calcRem(10px);
  }
}

.form-description {
  color: $grey2;
  font-size: calcRem(12px);

  * + & {
    margin-top: calcRem(12px);
  }
}

.custom-dropdown {
  .inline-label ~ & {
    display: inline-block;
    vertical-align: middle;
    min-width: calcRem(200px);
    margin-left: calcRem(4px);
  }
}

//Custom checkbox
.custom-checkbox-outer {
  //hide the actual input visually
  input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.custom-checkbox-inner {
  display: block;
}

.custom-checkbox {
  background-color: $white;
  border: 1px solid;
  border-color: $black;
  height: calcRem(24px);
  position: relative;
  width: calcRem(24px);
  
  display: block;
  float: left;

  input[type="checkbox"]:focus ~ & {
    border-width: 2px;
  }

  .has-error ~ & {
    border-color: $orange;
  }
}

.custom-checkbox-icon {
  @include centerer;
  display: none;
  height: calcRem(16px);
  width: calcRem(16px);

  input[type="checkbox"]:checked ~ .custom-checkbox & {
    display: block;
  }

  svg {
    display: block;
    height: inherit;
    width: inherit;
  }
}

.custom-checkbox-label {
  color: $black;
  display: block;
  overflow: hidden;
  padding-left: calcRem(8px);

  .custom-checkbox + & {
    padding-top: calcRem(4px);
  }
}

//Custom radio
.custom-radio-outer {
  //hide the actual input visually
  input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.custom-radio-inner {
  display: block;
}

.custom-radio {
  background-color: $white;
  border: 1px solid;
  border-color: $black;
  border-radius: calcRem(50px);
  height: calcRem(24px);
  position: relative;
  width: calcRem(24px);
  
  display: block;
  float: left;

  input[type="radio"]:focus ~ & {
    border-width: 2px;
  }

  .has-error ~ & {
    border-color: $orange;
  }
}

.custom-radio-icon {
  @include centerer;
  background-color: $black;
  border-radius: calcRem(50px);
  display: none;
  height: calcRem(14px);
  width: calcRem(14px);

  input[type="radio"]:checked ~ .custom-radio & {
    display: block;
  }
}

.custom-radio-label {
  color: $black;
  display: block;
  overflow: hidden;
  padding-left: calcRem(8px);

  .custom-radio + & {
    padding-top: calcRem(4px);
  }
}

.custom-file {
  display: inline-block;
  vertical-align: middle;
}

.custom-file-label {
  display: inline-block;
  vertical-align: middle;

  .custom-file + & {
    margin-left: calcRem(8px);
  }
}

.custom-date-range {
  display: flex;
  align-items: center;
  margin-bottom: calcRem(16px);
}

.custom-date-range-field {
  position: relative;
  width: calc(50%);
}

.custom-date-range-icon {
  font-family: $font-text-bold;
  line-height: 0;
  text-align: center;
  width: calcRem(40px);

  svg {
    margin: auto;
  }
}

// Some sample styling for the <date-picker> tag; written in Stylus
.picker {
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 3;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  table thead {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-color: $black;
    font-family: $font-text-bold;
  }

  table th {
    padding-bottom: calcRem(8px);
    padding-top: calcRem(8px);
  }

  table td {
    background-color: $white;
    border: 1px solid;
    border-color: $black;
    cursor: pointer;
    padding: calcRem(16px);
  }
  table td.cur {
    background-color: $grey1;
  }
  table td.selected {
    background-color: $black;
    color: $white;
    font-family: $font-text-bold;
  }

  button {
    font-family: $font-text-bold;
    padding: calcRem(8px);
    &.triangle-symbol {
      // Microsoft Edge converts these triangles to emoji if we don't add this.
      font-family: "Segoe UI Symbol";
    }
  }
}

.monthSelector {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: $black;
  padding: calcRem(16px);

  & > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .disabled {
    color: $grey1;
    pointer-events: none;
  }
}

date-picker {
  .picker {
    td {
      &.today {
        position: relative;
        &:after {
          content: "•";
          color: $black;
          display: block;
          bottom: 0;
          position: absolute;
          left: calc(50% - 0.2em);
        }
        &.selected {
          &:after {
            color: $white;
          }
        }
      }
      &.disabled {
        background-color: $grey2;
        pointer-events: none;
      }
    }
  }
}

.currMonth {
  font-family: $font-text-bold;
  font-size: calcRem(24px);
  min-width: calcRem(180px);
  text-align: center;
}

.currYear {
  min-width: calcRem(180px);
  text-align: center;
}

.asset-edit__form {
  asset-form-control {
    margin-top: 1.5rem;
  }

  .form-section:not(:first-of-type) {
    h3 {
      margin-top: 3rem;
    }
  }
}

.hours-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hours-dropdown {
    width: 35%;
  }
}

.custom-toggle-label {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-left: 1.5rem;
}

.custom-toggle {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  border: 1px solid $black;
}

.custom-toggle-indicator {
  @include centerer;
  background-color: $black;
  border-radius: calcRem(50px);
  display: none;
  height: 0.875rem;
  width: 0.875rem;

  :checked ~ .custom-toggle & {
    display: block;
  }
}


.auth-form {
  border-top: 0.25rem solid black;
  padding-top: 1.25rem;
  width: calc(50% - 1.25rem);
}

.auth-form__field {
  margin-top: 1rem;
}

.auth-form__instructions {
  width: 75%;
  margin-bottom: 1.5rem;
}

.auth-form__response {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 0.75rem;
}

.auth-form__response--error {
  color: #ed7000;
}

.auth-form__response--success {
  color: #00ac3e;
}

.auth-form__controls {
  margin-top: 2rem;
}


//Custom light switch/slab
.custom-switch-outer {
  position: relative;
  height: calcRem(38px);
  width: calcRem(102px);
  line-height: 100%;
  background-color: $u-grey2;

  & + & {
    margin-top: calcRem(4px);
  }
}

.custom-switch-label {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  
  font-family: $font-text-bold;
  font-size: calcRem(14px);
}

.custom-switch-label--left,
.custom-switch-label--right {
  display: flex;
  position: absolute;
  top: calcRem(-2px);
  align-items: center;
  justify-content: center;
  width: calc( 100% / 2);
  height: 100%;
  transition: 
    color 125ms ease-in,
    background-color 125ms ease-in;
  color: $u-grey1;
}

.custom-switch-label--left {
  left: 0;
  
  color: $u-grey1;
  :checked ~ .custom-switch-label & {
    color: $black;
  }
}

.custom-switch-label--right {
  right: 0;
  
  color: $black;
  :checked ~ .custom-switch-label & {
    color: $u-grey1;
  }
}

.custom-switch {
  border: calcRem(1px) solid $u-grey1;
  position: relative;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.custom-switch-slab {
  background-color: $white;
  border-bottom: calcRem(4px) $red solid;
  bottom: 0;
  height: 100%;
  width: 50%;
  position: absolute;
  transform: translateX(100%);
  transition: transform 250ms;

  :checked ~ .custom-switch & {
    transform: translateX(0%);
    transition: transform 250ms;
  }
}
