.block {
  position: relative;
}

.hero-block {
  @extend .block;
  margin-bottom: calcRem(100px);
}

.hero-block--template-builder {
  margin-bottom: 3.25rem;
}

.intro-block {
  @extend .block;
  margin-bottom: calcRem(20px);
}

.block__body {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.block__title {
  font-size: calcRem(54px);
  line-height: 0.953125;
  margin-bottom: 0;
  padding-right: 30%;
  word-break: break-word;

  .block__controls + & {
    float: left;
    padding-right: 0;
    width: 56%;
  }
}

.block__subtitle {
  font-size: calcRem(36px);
  margin-bottom: 0;
  word-break: break-word;
}

.block__info {
  font-size: calcRem(20px);
  margin-bottom: 0;
  line-height: 1.2;
}

.block__info--width-m {
  max-width: calcRem(800px);
}

.block__title + .block__info {
  margin-top: calcRem(18px);
}

.block__controls {
  float: right;
  font-size: calcRem(16px);
  padding-left: calcRem(16px);
  width: 44%;
  text-align: right;

  project-action {
    margin-left: calcRem(8px);
    margin-right: calcRem(8px);
  }
  project-action:first-of-type {
    margin-right: 0;
  }
  project-action:last-of-type {
    margin-left: 0;
  }
}

.block__list {
  clear: both;
  display: flex;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;

  .block__body + & {
    margin-top: calcRem(50px);
  }

  p {
    margin-bottom: 0;
  }
}

.block__item {
  border-left: 1px solid;
  border-color: $grey1;
  padding-left: calcRem(20px);
  padding-right: calcRem(16px);

  &:first-of-type {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
    padding-right: 0;
  }
}

home-app {
  display: block;
}

.block__heading {
  display: flex;
  justify-content: space-between;
}

.block__control-link {
  position: relative;
  padding: 0;
}

.block__control-link-icon {
  padding: 0;
  // height: 100%;
  background-color: $lite;
  border-radius: 50%;
  overflow: hidden;
  transition: box-shadow 500ms;

  &:hover,
  &:focus {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  }
}

.block__control-tooltip {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms;
}

.block__control-link:hover .block__control-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms;
}