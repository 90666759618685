@keyframes splide-loading {

  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(1turn)
  }
}

.splide--draggable>.splide__slider>.splide__track,
.splide--draggable>.splide__track {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.splide--fade>.splide__slider>.splide__track>.splide__list,
.splide--fade>.splide__track>.splide__list {
  display: block
}

.splide--fade>.splide__slider>.splide__track>.splide__list>.splide__slide,
.splide--fade>.splide__track>.splide__list>.splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0
}

.splide--fade>.splide__slider>.splide__track>.splide__list>.splide__slide.is-active,
.splide--fade>.splide__track>.splide__list>.splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1
}

.splide--rtl {
  direction: rtl
}

.splide--ttb.is-active>.splide__slider>.splide__track>.splide__list,
.splide--ttb.is-active>.splide__track>.splide__list {
  display: block
}

.splide__container {
  box-sizing: border-box;
  position: relative
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  transform-style: preserve-3d
}

.splide__sr {
  display: none!important;
}

.indicator {
  height: 4px!important;
  transition: none!important;
  border-radius: 3px;
  z-index: 10;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto
}

.splide__progress__bar {
  width: 0
}

.splide_wrapper {
  position: relative;
  width: 100%;
  min-height: 396px;
}

@media (max-width: 480px) {

  .splide_wrapper {
    display: block;
  }
}

.splide {
  outline: none;
  position: relative;
  visibility: hidden;

  &:hover {
    .splide__arrows {
      opacity: 1;
    }
  }
}

.splide.is-initialized,
.splide.is-rendered {
  visibility: visible
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  outline: none;
  position: relative
}

.splide__slide img {
  vertical-align: bottom
}

.splide__slider {
  position: relative
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 10px!important;
}

.scrubber_bg {
  position: relative;
  height: 6px;
  width: 45%;
  background-color: #D7DADA;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6px;
}

/* Splide JS BLock */

.splide {
  --page-color: currentColor;
  --page-color-current: blue;
  --page-align: center;

  --arrow-color: currentColor;
  --arrow-color-hover: currentColor;

  --slide-padding: 0;
  --slide-border-radius: 0;
  --slide-text-align: left;
  --slide-bg-color: transparent;
  --slide-tx-color: currentColor;
  --slide-border: none;
  --slide-justify-content: flex-start;
  --slide-height: 0;

  --image-blend-mode: normal;
  --image-opacity: 1;
  background-color: white;
  width: 100%;
}

.splide__arrows {
  position: absolute;
  height: 100%;
  width: calc(100% - 24px * 2);
  max-width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding: 24px calc(24px * 2) calc(24px * 3);
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity 150ms ease-in;
}

@media(max-width:1200px) {

  .splide__arrows {
    width: calc(100% - 24px * 4);
    max-width: calc(100% - 24px * 4);
    padding-left: 0;
    padding-right: 0;
  }
}

.splide__arrow {
  padding: 0;
  background: transparent;
  border: 1px solid #DED9DE;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  z-index: 100;
  pointer-events: auto;
}

.splide__arrow:hover {
}


.splide__arrow svg {
  display: none;
  width: 100%;
  height: auto;
  border: none;
}

.splide__arrow {
  background-repeat: no-repeat;
  background-size: auto 24px;
  background-position: center;
  position: relative;
}

.splide__arrow--prev {
  background-image: url('../../images/arrow-left.svg');
  margin-right: auto;
  transform: scale(-1) rotate(180deg);
}

.splide__arrow--next {
  background-image: url('../../images/arrow-right.svg');
  margin-left: auto;
}

.splide__arrow:disabled {
  display: none;
}

.splide__arrow svg path {
  fill: currentColor;
}

.splide__pagination {
  padding-left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
  font-family: 'Arial', sans-serif;
}

.splide__pagination li {
  margin-bottom: 0 !important;
  margin-left: -2px;
}

.splide__pagination button {
  width: 48px;
  height: 2px;
  padding: 0;
  background: #D7DADA;
  position: relative;
  cursor: pointer;
  margin: 0;
  outline: none;
  transition: 0.5s;
  box-sizing: content-box;
}

.splide__pagination button:focus {
  outline: 0 solid !important;
}

.splide__pagination__page.is-active {
  height: 6px;
  background: #6E7171;
  z-index: 100;
}


.splide__pagination__page:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0;
  top: -20px;
}

.splide__slide> :last-child {
  margin-bottom: 0;
}

.splide--rtl .splide__slide {
  text-align: right;
}