.carousel {
  position: relative;
}

.carousel__item {
  display: flex;
  align-items: center;
  min-height: 467px;
  height: 100%;
  background-color: $monarch-grey;
  border-radius: 30px;

}

.carousel__img {
  order: 2;
  width: 50%;
  min-height: 389px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 30px 30px 0;


  svg {
    height: 65%;
    width: 65%;
  }
}

.carousel__body {
  order: 1;
  width: 50%;
  padding: 2rem 2rem 2rem 3.5rem;
  font-size: 16px;

  :last-child {
    margin-bottom: 0;
  }
}

.carousel__title {
  font-family: $font-disp-bold;
  font-size: 48px;
  line-height: 1.2;
  color: #F50A23
}

.carousel__subtitle {
  font-size: 1.25rem;
  line-height: 1;
}

.carousel__info {
  .carousel__title + &,
  .carousel__subtitle + & {
    margin-top: calcRem(40px);
  }
}