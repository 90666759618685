.site-nav {
  font-family: $font-text-bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .wrap {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.projects-link {
  padding-top: calcRem(12px);
}

.action-link {
  color: #F50A23;
  text-decoration: none;
  margin-right: 5px;
}

.site-nav-list {
  margin-bottom: 0;
}

.site-nav-item {
  position: relative;

  & + & {
    margin-left: 2.125rem; //34px
  }
}

.site-nav-link {
  border-bottom: 4px solid;
  border-color: transparent;
  color: $monarch-dark-grey;
  display: block;
  font-family: $font-text-bold;
  padding-bottom: calcRem(7px);
  padding-top: calcRem(12px);
  transition: border-color 500ms;

  .active &,
  &:focus,
  &:hover {
    border-color: $red;
    color: $black;
  }
}

.visual-indicator {
  position: absolute;
  top: 5px;
  right: -0.6rem;
  padding-bottom: 0.625rem;
}

.utility-nav {
  font-size: calcRem(10px);
  margin-bottom: 5px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 100%;

  .wrap {
    max-width: calcRem(1400px);
  }
}

.utility-nav-list {
  margin-bottom: 0;
}

.utility-nav-item {
  vertical-align: middle;
  font-size: calcRem(11px);

  &:first-of-type {
    border-right: 1px solid;
    border-color: $black;
    padding-right: calcRem(5px);
  }

  & + & {
    margin-left: calcRem(5px);
  }
}

.utility-nav-link {
  &:focus,
  &:hover {
    color: $blue;
  }
}

.utility-nav-icon {
  display: inline-block;
  height: calcRem(14px);
  width: calcRem(14px);
  vertical-align: middle;

  svg {
    height: inherit;
    width: inherit;
  }
}

.utility-nav-info {
  margin-bottom: 0;
  font-size: calcRem(11px);
}

.footer-nav {
  font-size: calcRem(10px);
}

.footer-nav-list {
  margin-bottom: 0;
}

.footer-nav-item {
  & + & {
    border-left: 1px solid;
    border-color: $grey2;
    margin-left: calcRem(5px);
    padding-left: calcRem(5px);
    vertical-align: bottom;
  }
}

.footer-nav-link {
  &:focus,
  &:hover {
    color: $blue;
  }
}

.home-footer-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: unset;
}

.home-footer-item {
  padding-top: 8px;
  font-size: calcRem(11px);
}

.site-footer-home {
  padding-top: 32px;
  border-top: 1px solid $grey1;
  margin-top: 60px;
}

.content-nav {
  font-family: $font-text-bold;
}

.content-nav-list {
  margin-top: calcRem(16px);
  padding-bottom: calcRem(20px);
  padding-top: calcRem(20px);
}


.content-nav-item {
  margin-right: calcRem(16px);

  &:first-of-type {
    border-right: 2px solid $grey1;
    padding-right: calcRem(16px);
  }
}

.content-nav-link {
  padding-bottom: calcRem(9px);
  padding-top: calcRem(9px);
}
