.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  margin-bottom: calcRem(20px); 
  overflow: hidden;
  
}

.list-card {
  @extend .card;
  background-color: $lite;
  overflow: visible;
  padding: 0;
  width: 100%;
  transition: box-shadow 500ms;

  .cards &:nth-child(4n) {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  }
}

.list-card__body {
  visibility: hidden;
  max-height: 0;
  height: 0;
  overflow: hidden;
  display: flex;

  .list-card.expanded & {
    padding: 0 calcRem(20px) calcRem(20px) calcRem(20px);
    overflow: visible;
    height: auto;
    max-height: none;
    visibility: visible;
  }

  .list-card--assets.expanded & {
    padding: 0 calcRem(20px) calcRem(20px) 7rem;
  }
}

.list-card__details {
  width: 30%;
  min-width: 30%;
  padding: 0;
  list-style: none;

  .list-card--assets & {
    width: 25%;
    min-width: 25%;
  }
}

.list-card__body-column {
  width: 100%;
  max-width: 12rem;
  box-sizing: border-box;

  & + & {
    margin-left: 1rem;
  }

}

.list-card__expand-control {
  width: 5%;
  font-size: 1.2rem;
}

.file-card {
  @extend .card;
  margin-bottom: calcRem(40px);

  &--alternate-action-view {
    margin-bottom: 1.5rem;
  }
}

.card__heading {
  display: flex;
  justify-content: space-between;

  .list-card & {
    padding: calcRem(20px) 0 calcRem(20px) calcRem(20px);
  }
  .list-card--assets & {
    padding: calcRem(20px) calcRem(20px) calcRem(20px) calcRem(20px);
  }
}

.card__controls {
  display: flex;

  .icon {
    margin-left: 1.5rem;
  }
}

.card__img {
  float: left;
  width: calcRem(120px);

  img {
    display: block;
    max-width: 100%;
  }

  .file-card--alternate-action-view & {
    float: unset;
  }
}

.card__body {
  overflow: hidden;
  padding-left: calcRem(20px);

  .file-card--alternate-action-view & {
    padding: 0.5rem 0 0 0;
  }
}

.card__title {
  font-size: calcRem(18px);
  line-height: 1;
  word-wrap: break-word;

  .list-card & {
    font-size: calcRem(24px);
  }

  &.file_upload {
    font-size: calcRem(16px);
  }

  .card__heading & {
    width: 100%;
    margin: 0;
  }

  .file-card--alternate-action-view & {
    margin-bottom: 0.5rem;
  }
}

.card__info {
  font-size: calcRem(12px);
}

.card__nav {
  margin-bottom: 0;
  padding-left: 0;

  img + & {
    margin-top: calcRem(8px);
  }

  .card__list + & {
    margin-top: calcRem(16px);
  }

  .file-card & {
    text-align: center;
  }

  .file-card--alternate-action-view & {
    text-align: left;
  }

  .card__heading & {
    width: 25%;
  }
}

.card__status {
  vertical-align: middle;
  width: 20%;
  text-align: right;
  padding-right: 1rem;

  .status-text {
    font-family: $font-text-bold;
    text-transform: uppercase;
    background-color: #000000;
    color: #ffffff;
    border-radius: 20px;
    padding: .3rem .75rem;
  }
}

.card__item {

  & + & {
    margin-left: calcRem(5px);
  }

  .list-card & + & {
    margin-left: calcRem(10px);
    padding-left: calcRem(10px);
    border-left: 1px solid #5c5a5c;
  }
}

.card__term {
  font-family: $font-text-reg;
  font-size: calcRem(12px);

  .card__data + & {
    margin-top: calcRem(16px);
  }
}

.card__data {
  font-family: $font-text-bold;

  .list-card & {
    font-size: calcRem(12px);
  }

  .file-card & {
    font-size: calcRem(12px);
  }
}

.card__link {
  font-family: $font-text-reg;
  text-decoration: underline;

  .file-card & {
    font-size: calcRem(12px);
  }
}

.card__list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.card__asset-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card__title {
    max-width: 50%;
  }
  .card__asset-status {
    font-size: 0.75rem;
    padding-left: 1rem;
  }
}

.card__thumbnail-container {
  width: 5rem;
  margin-right: 1rem;
}

.card__thumbnail {
  display: block;
  width: 100%;
  height: auto;
}

.card__notification {
  align-self: flex-end;
  width: 100%;
  text-align: right;
}