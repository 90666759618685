.builder {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.builder__icons {
    // display: inline-block;
    // vertical-align: middle;
}

.builder__icon {
    cursor: pointer;
    height: calcRem(34px);
    width: calcRem(34px);

    svg {
        height: inherit;
        width: inherit;
    }
}

.builder__info {
    margin-top: calcRem(16px);
}

.builder__link {
    font-family: $font-text-bold;
}

.builder__buttons {
    display: inline-block;
    vertical-align: middle;

    .builder__icons + & {
        margin-left: calcRem(16px);

        @include tablet-landscape-up {
            margin-left: 0;
            margin-top: calcRem(32px);
        } 
    }
}

.builder__button {
    @include layout-machine(2,1,2,calcRem(12px));
    & + & {
        margin-left: 0;
    }
}
