//OVERRIDE Default Owl Styles
.owl-item {
  overflow: hidden;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  border-radius: 0 30px 30px 0;
  // width: auto; //commented out for IE11
}

.owl-dots {
  margin-top: calcRem(32px);
  text-align: center;
}

.owl-dot {
  margin-left: calcRem(4px);
  margin-right: calcRem(4px);
  height: calcRem(4px);
  position: relative;
  width: calcRem(40px);

  &:before {
    background-color: $grey2;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    max-height: calcRem(1px);
    position: absolute;
    transition: max-height 250ms;
    width: 100%;
  }
  
  &:hover:before,
  &.active:before {
    max-height: calcRem(4px);
    transition: background-color 125ms, max-height 125ms;
  }
  
  &:hover:before {
    background-color: $grey2;
  }
  
  &.active:before {
    background-color: #FF3C2D;
  }
}

.owl-prev,
.owl-next {
  position: absolute;
  top: calc(50% - 21px);
  width: calcRem(44px);
  height: calcRem(44px);
  transform: translateY(-50%);
  background-color: rgba(255,255,255,0.5);
  border-radius: 50%;
  border: 1px solid #DED9DE;
  stroke: black;
  stroke-width: 2%;
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-repeat: no-repeat;
  background-size: auto 24px;
  background-position: center;

  span {
    display: none;
  }


  svg {
    opacity: 1;
    height: 15px;
    width: 20px;
    margin-top: 12px;
  }
}

.owl-prev {
  left: 10px;
  background-image: url('../../images/arrow-left.svg');
}

.owl-next {
  right: 10px;
  background-image: url('../../images/arrow-right.svg');
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  &:hover {
    .owl-nav > .owl-prev, .owl-next {
      opacity: 1;
    }
  }
}



