.list-control {
  display: block;
}

.list-control__labels {
  margin-bottom: 1rem;
}

.list-control__body {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  .list-control--assets & {
    justify-content: space-between;
  }
}

.list-control__paging {
  text-align: right;
}

.paging-setting {
  padding: 0 0.25rem;

  & + & {
    border-left: 1px solid $black;
  }

  &.active {
    font-family: $font-text-bold;
  }
}

.filters-label {
  font-family: $font-text-bold;
}

.filters-group-list {
  display: flex;
  width: 50%;
  max-width: 50%;
  flex-wrap: wrap;

  /* margins are to line up the flex items when they wrap to a new row */
  margin-left: -1rem;
  margin-right: 1rem;
}

.filter-group {
  box-sizing: border-box;
  margin-left: 1rem;
  margin-bottom: .65rem;
}

.filter-button {
  padding: 0.75rem 1.5rem;
  background-color: $lite;
  font-family: $font-text-bold;
  color: $grey2;

  &.active {
    background-color: $grey2;
    color: #ffffff;
  }
}

.filter-popup {
  display: none;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 1rem;
  z-index: 2;

  // column width then count
  columns: 10rem 3;
  column-gap: 1.5rem;

  &.active {
    display: block;
    position: absolute;
  }
}

.filter-popup__list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0.5rem 0;
    break-inside: avoid;

    &:first-child {
      margin-top: 0;
    }
  }

  .filter-label {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: $black;
  }
}

.sort__group,
.sort__body {

  .list-control__body & {
    width: 25%;
  }
}