$unit: 16px;

//Primary Color Palette
$red: #d52b1e;
$lite: #f6f6f6;
$grey1: #d8dada;
$grey2: #747676;
$dark: #333333;
$monarch-grey: #F3EDE0;
$monarch-dark-grey: #6F7171;


//Secondary Color Palette
$orange: #ed7000;
$yellow: #ffbc3d;
$blue: #0088ce;
$green: #00ac3e;

//Base colors
$black: #000000;
$white: #ffffff;

//neutral colors
$u-grey1: #cccccc;
$u-grey2: #f6f6f6;

$z: 0;

//Base font stack
$font-base: 'Helvetica Neue', Helvetica, sans-serif;

//Fonts Text (Only used if 14px or smaller)
$font-text-reg: 'NeueHaasGroteskText W01', $font-base;
$font-text-italic: 'NHaasGroteskTXW01-56It';
$font-text-bold: 'NHaasGroteskTXW01-75Bd';
$font-text-bold-italic: 'NHaasGroteskTXW01-76BdI';

//Fonts Text (Only used if larger than 14px)
$font-disp-reg: 'NeueHaasGroteskDisp W01', $font-base;
$font-disp-italic: 'NHaasGroteskDSW01-56It';
$font-disp-bold: 'NHaasGroteskDSW01-75Bd';
$font-disp-bold-italic: 'NHaasGroteskDSW01-76BdI';

//Calculating Rem size based on 16px
@function calcRem($size) {
    $remSize: $size / $unit;
    @return #{$remSize}rem;
}

@mixin phone-only {
    //599
    @media (max-width: 37.4375rem) {
        @content;
    }
}

@mixin tablet-portrait-up {
    //600
    @media (min-width: 37.5rem) {
        @content;
    }
}

@mixin tablet-landscape-down {
    //900 - .5px
    @media (max-width: 56.25rem - .03125rem) {
        @content;
    }
}
@mixin tablet-landscape-up {
    //900
    @media (min-width: 56.25rem) {
        @content;
    }
}

@mixin desktop-down {
    //1200 - .5px
    @media (max-width: 75rem - .03125rem) {
        @content;
    }
}
@mixin desktop-up {
    //1200
    @media (min-width: 75rem) {
        @content;
    }
}

@mixin large-desktop-down {
    //1400 - .5px
    @media (min-width: 87.5rem - .03125rem) {
        @content;
    }
}
@mixin large-desktop-up {
    //1400
    @media (min-width: 87.5rem) {
        @content;
    }
}

//specify min-width
@mixin min-width($minWidth) {
    @media screen and (min-width: $minWidth) {
        @content;
    }
}

//specify max-width
@mixin max-width($maxWidth) {
    //max-width - .5px
    @media screen and (max-width: $maxWidth - .03125rem) {
        @content;
    }
}

//specify min-width
@mixin min-height($minHeight) {
    @media screen and (min-width: $minHeight) {
        @content;
    }
}

//specify max-width
@mixin max-height($minHeight) {
    //max-width - .5px
    @media screen and (max-width: $minHeight - .03125rem) {
        @content;
    }
}

//specify 2 widths between min-width and max-width
@mixin between($minWidth, $maxWidth) {
    @media screen and (min-width: $minWidth) and (max-width: $maxWidth - .0125) {
        @content;
    }
}

@mixin centerer($x: true, $y: true) {
    position: absolute;

    @if ($x and $y) {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    } @else if ($x) {
        left: 50%;
        transform: translateX(-50%) translateY(0%);
    } @else if ($y) {
        top: 50%;
        transform: translateX(0%) translateY(-50%);
    }
}
