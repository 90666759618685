

@mixin zebralist ($colorway1: $white, $colorway2: $black) {
	&:nth-child(odd) {
		background-color: $colorway1;
	}
	&:nth-child(even) {
		background-color: $colorway2;
	}
}


.inline-list {
	li {
		display: inline-block;
	}
}

.basic-list {
	@extend .inline-list;
	padding: 0;
}

.basic-list__item {
	@include zebralist($white, $lite);
	padding: calcRem(24.5px) calcRem(27px);
	width: 100%;
}

.basic-list__body {
  display: flex;
  justify-content: space-between;
  font-size: calcRem(14px);
}

.basic-list__heading {
  font-family: $font-text-bold;
  font-size: calcRem(16px);
}

.basic-list__control {
	align-self: center;
}