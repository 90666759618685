$container: calcRem(1272px);
$push: 20px;
$gutter: 20px;

.constrain {
    min-width: $container;
}

.wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: $container;
    position: relative;
    width: 100%;
}

.cell {
    padding-bottom: $push;
    padding-left: $push;
    padding-right: $push;
    padding-top: $push;
}

.grid-flex {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
}

//takes 4 parameters
//if none OR $count = 1 or lower, then it does nothing
//$count: number of columns
//$numerator is the whole column to be broken up
//$denominator is the amount of equal columns to be broken into
//amount of space between each column
@mixin layout-machine($count: 1, $numerator: 1, $denominator: 1, $spacing: $gutter) {
    $ratio: percentage($numerator / $denominator);
    // @debug #{$ratio};

    @if $count > 1 {
        float: left;
        margin-right: $spacing;
        width: calc(#{$ratio} - #{$spacing} + #{$spacing}/#{$count});

        &:nth-of-type(#{$count}n) {
            margin-right: 0;
        }
    } @else {
        width: #{$ratio};
    }
}

.row,
.inner-row,
.grid {
    & + & {
        margin-top: calcRem($gutter*3);
    }
}

.row {
    position: relative;
}

.col,
.cols {
    // get the last child on mobile
    // and give it 0 margin
    &:last-of-type {
        @include tablet-landscape-down {
            margin-bottom: 0;
        }
    }

    & .col--extra-space-below {
        margin-bottom: 3rem;
    }
}

@for $i from 1 to 7 {
    // .colcount--#{$i} {
    //     .col:nth-last-child(-n+#{$i}) {
    //         margin-bottom: 0;
    //     }
    // }

    .cols--1-2 {
        .colcount--#{$i} > & {
            @include layout-machine($i,1,2,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,1,2,$gutter);
        }
    }

    .cols--1-3 {
        .colcount--#{$i} > & {
            @include layout-machine($i,1,3,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,1,3,$gutter);
        }
    }

    .cols--2-3 {
        .colcount--#{$i} > & {
            @include layout-machine($i,2,3,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,2,3,$gutter);
        }
    }

    .cols--1-4 {
        .colcount--#{$i} > & {
            @include layout-machine($i,1,4,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,1,4,$gutter);
        }
    }

    .cols--2-4 {
        .colcount--#{$i} > & {
            @include layout-machine($i,2,4,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,2,4,$gutter);
        }
    }

    .cols--3-4 {
        .colcount--#{$i} > & {
            @include layout-machine($i,3,4,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,3,4,$gutter);
        }
    }

    .cols--1-5 {
        .colcount--#{$i} > & {
            @include layout-machine($i,1,5,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,1,5,$gutter);
        }
    }

    .cols--2-5 {
        .colcount--#{$i} > & {
            @include layout-machine($i,2,5,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,2,5,$gutter);
        }
    }

    .cols--3-5 {
        .colcount--#{$i} > & {
            @include layout-machine($i,3,5,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,3,5,$gutter);
        }
    }

    .cols--4-5 {
        .colcount--#{$i} > & {
            @include layout-machine($i,4,5,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,4,5,$gutter);
        }
    }

    .cols--1-6 {
        .colcount--#{$i} > & {
            @include layout-machine($i,1,6,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,1,6,$gutter);
        }
    }

    .cols--2-6 {
        .colcount--#{$i} > & {
            @include layout-machine($i,2,6,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,2,6,$gutter);
        }
    }

    .cols--3-6 {
        .colcount--#{$i} > & {
            @include layout-machine($i,3,6,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,3,6,$gutter*2);
        }
    }

    .cols--4-6 {
        .colcount--#{$i} > & {
            @include layout-machine($i,4,6,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,4,6,$gutter);
        }
    }

    .cols--5-6 {
        .colcount--#{$i} > & {
            @include layout-machine($i,5,6,$gutter*2);
        }
        .grid.colcount--#{$i} > & {
            @include layout-machine($i,5,6,$gutter);
        }
    }
}


//2-Column Template 
.has-sidebar {
    &.colcount--2 .cols:last-of-type {
        padding-left: calcRem(60px);
    }
}
