.activity {
  background-color: $grey1;
  border: 1px solid;
  border-color: $grey1;
  margin-bottom: calcRem(20px);
  margin-top: calcRem(20px);
  padding: calcRem(20px);
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.activity__wrapper {
  //if there is a badge
  .activity__badge + & {
    padding-left: calcRem(54px + 20px);
  }

  .is-add-mode & .form-label {
      color: $white;
  }

  .edit & {
    width: 100%;
    text-align: right;
  }
}

.activity--comment {
  @extend .activity;
  background-color: $white;
  border-color: $grey1;
  min-height: calcRem(98px);

  &.is-add-mode {
    background-color: $black;
    border-color: $black;
    color: $white;
  }

  &.is-add-mode {
    border-color: $black;
  }

  .form-field {
    min-height: 2rem
  }
}

.activity__header {
  float: right;
  font-size: calcRem(11px);
  margin-bottom: calcRem(16px);

  //flexbox goodies
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.activity__content {
  margin-bottom: 0;

  .status {
    font-family: $font-text-bold;
  }

  i {
    vertical-align: sub;
  }

  //if next to an edit box
  & + .edit {
    margin-top: calcRem(16px);
  }

  .tracker {
    text-transform: capitalize;
    + tool-tip {
      padding-left: 0.5em;
    }
  }
}
.tracking-comment {
  padding: 0.3rem;
  color: $grey2;
}

.tracking-edit-container {
  cursor: pointer;
  position: relative;
  .tracking-edit--collapsed {
    max-height: 100px;
    overflow-y: hidden;
    transition: all 0.3s ease;
    p {
      margin-top: calcRem(8px);
    }
  }
  .tracking-edit--expanded {
    max-height: 0px;
    overflow-y: hidden;
    transition: all 0.3s ease;
    .h4 {
      margin-bottom: calcRem(8px);
    }
    .table {
      padding-bottom: calcRem(24px);
      .thead ~ .tbody .tr:nth-child(odd) {
        background-color: $white;
      }
    }
    .expanded & {
      max-height: 9999px
    }
  }
  .expand-plus {
    position: absolute;
    right: 0;
    top: 0;
    &:after, &:before {
      content: '';
      display: block;
      width: calcRem(16px);
      height: calcRem(16px);
      border-bottom: 2px solid $black; 
      position: absolute;
      top: 0;
      right: 0;
    }
    &:before {
      transition: all 0.3s ease;
      transform: rotate(90deg);
      top: calcRem(7px);
      right: calcRem(-7px);
    }
  }
  &.expanded {
    .tracking-edit--collapsed {
      max-height: 0px;
    }
    .tracking-edit--expanded {
      max-height: 1500px;
    }
    .expand-plus {
      &:before {
        transform: rotate(0deg);
        top: 0;
        right: 0;
      }
    }
  }
}

.activity__label {
  font-size: calcRem(12px);
}

.activity__info {
  min-height: 1px;
  word-wrap: break-word;
}

.activity__timestamp {
  .is-add-mode & svg {
    fill: $white;
  }
}

.activity__icon {
  display: inline-block;
  vertical-align: middle;
  height: calcRem(16px);
  opacity: .25;
  transition: 250ms ease;

  //when hovering
  //or in edit mode
  &:hover,
  .is-add-mode & {
    opacity: 1;
    transition-delay: 125ms;
  }

  svg {
    height: inherit;
  }

  //when an icon is next to another
  & + & {
    margin-left: calcRem(4px);
  }

  //when icon is next to a span
  & + span {
    display: inline-block;
    vertical-align: middle;
    border-left: 1px solid;
    border-left-color: $grey1;
    margin-left: calcRem(10px);
    padding-left: calcRem(10px);
    transition: border-left-color 250ms ease;
  }

  //do something to the span when hovering
  //or when in edit mode
  .is-add-mode & + span {
    border-left-color: $white;
  }
}

.activity__badge {
  left: calcRem(20px);
  position: absolute;
  top: calcRem(20px);

  .is-add-mode & {
    background-color: $lite;
    color: $black;
  }
}


.comment-users {
  padding: 1rem;
  background-color: $lite;

}

.comment-users {
  margin-bottom: 1rem;
}

.edit {

  .form-field {
    margin-bottom: 1rem;
  }

  .is-edit-mode & .form-label {
    color: $grey2;
  }
}

.activity__buttons {
  margin-top: calcRem(16px);

  .is-add-mode & .button {
    background-color: $white;
    border-color: $white;
    color: $black;
  }

  .is-add-mode & .white-button {
    background-color: $black;
    color: $white;
  }
}