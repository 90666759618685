.sort {
  display: block;
  font-size: 0px;
  margin-bottom: calcRem(16px);
}

.sort__header,
.sort__body {
  font-size: calcRem(16px);
  display: inline-block;
  vertical-align: middle;
}

.sort__header {
  width: 80%;
}

.sort__body {
  position: relative;
  width: 20%;
}

.sort__group {
  float: left;

  & + & {
    margin-left: calcRem(16px);
  }
}
