.pods {
  border-top: 1px solid;
  border-color: $grey1;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: calcRem(20px);
  overflow: hidden;
  padding-left: 0;
  padding-top: calcRem(20px);

  display: flex;
  justify-content: space-between;
}

.pod {
  background-color: $lite;
  margin-right: calcRem(20px);
  padding: calcRem(20px);
  text-align: center;
  width: 50%;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.pod__title {
  font-family: $font-disp-bold;
  font-size: calcRem(32px);
}

.pod__info {
  margin-bottom: calcRem(32px);
}

.pod__asset-list {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey1;
  height: calcRem(148px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.pod__single-asset {
  display: flex;
  padding: 0.625rem;

  &:nth-child(odd) {
    background-color: white;
  }
}

.pod__asset-name {
  font-size: calcRem(20px);
  font-family: $font-text-bold;
}

.pod__asset-wrapper {
  position: relative;
  height: 185px;
  width: 160px;
  background-color: $monarch-dark-grey;
  margin: 0 auto;
}

.pod__recent-heading-wrapper {
  margin: 60px 0 28px 0;
}

.pod__recently-added-asset {
  display: flex;
  flex-direction: column;
  min-height: 352px;
  width: 220px!important;
  padding: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  margin-right: 10px;
  justify-content: space-between;

}