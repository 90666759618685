

// LOADER
// list-B: total assets
$loader-dots: (
    loader-1,
    loader-2,
    loader-3
);

// Animation
@mixin animateLoaderFromList(){
    @each $key in $loader-dots {
        $i: index($loader-dots, $key);
        $stagger: .125 * $i;
        
        & > .#{$key} {
            transition: background-color 250ms ease-out;
            animation: dots-keyframes .65s #{$stagger}s infinite forwards;
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}

@keyframes dots-keyframes {
    0% {
        background-color: #b5b3b5;
    }
    40% {
        background-color: #b5b3b5;
    }
    45% {
        background-color: #747674;
    }
    48% {
        background-color: #747674;
    }
    50% {
        background-color: #050305;
    }
    55% {
        background-color: #050305;
    }
    57% {
        background-color: #5c5a5c;
    }
    60% {
        background-color: #5c5a5c;
    }
    65% {
        background-color: #b5b3b5;
    }
    100% {
        background-color: #b5b3b5;
    }
}

.show-loader {
    position: relative;
    pointer-events: none;
}

.loader {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*transform: translateX(-50%) translateY(-50%);*/
    padding: 0;
    /*width: calcRem(160px);*/
    background: rgba(0,0,0,0.2);
    text-align: center;
    z-index: 10;
    @include animateLoaderFromList();
    .show-loader & {
        display: block;
    }
}

.loader > [class*="loader-"] {
    display: inline-block;
    height: calcRem(24px);
    width: calcRem(24px);
    border-radius: 50%;
    background-color: #b5b3b5;
    transition: background-color 150ms ease-in;

    top: 50%;
    position: absolute;
    left: calc(50% - 48px);
    &.loader-2 {
        left: calc(50% - 12px);
    }
    &.loader-3 {
        left: calc(50% + 24px);
    }
}