.content-img {
  margin-bottom: calcRem(16px);
  max-width: 100%;

  // find the last child
  //and remove and margin bottom
  &:last-child {
      margin-bottom: 0;
  }
}

.img {
  display: block;
  max-width: 100%;
}
