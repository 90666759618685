button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: inherit;
  outline: none;
  padding: 0;
}

.button {
  background-clip: border-box;
  background-color: $black;
  border-radius: 50px;
  border: 1px solid;
  border-color: $black;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-text-bold;
  font-size: calcRem(18px);
  padding: calcRem(12px) calcRem(28px) calcRem(10px);
  text-align: center;
  transition: all 500ms;

  & + & {
    margin-left: calcRem(10px);
  }

  &[disabled] {
    background-color: $grey1;
    border-color: $grey1;
    cursor: not-allowed;
  }
}

.white-button {
  @extend .button;
  color: $black;
  background-color: $white;
}

.silent-button {
  @extend .button;
  background-color: transparent;
  border-color: transparent;
  color: $black;
  font-family: $font-text-reg;
}

.full-button {
  display: block;

  //TODO For Prototyping Only
  & + & {
    margin-top: calcRem(10px);
  }

  .button + & {
    margin-top: calcRem(10px);
    margin-left: 0;
  }
}

.small-button {
  @extend .button;
  padding: calcRem(12px) calcRem(24px);
  font-size: calcRem(14px);
}

.xsmall-button {
  @extend .button;
  padding: calcRem(8px) calcRem(16px);
  font-size: calcRem(12px);
}

//a group of buttons
.button-group {
  margin-bottom: calcRem(-4px);

  .button {
    margin-bottom: calcRem(4px);
    margin-left: calcRem(2px);
    margin-right: calcRem(2px);
  }

  .button:first-of-type {
    margin-left: 0;
  }
  .button:last-of-type {
    margin-right: 0;
  }

}

.action-loading {
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: default;
}

.button-loader {
  display: inline-block;
  border: 0.125rem solid $white;
  border-radius: 50%;
  border-top: 0.125rem solid transparent;
  width: 1.25rem;
  height: 1.25rem;
  animation: spin 2s linear infinite;
  transition: 1s;
}

project-action {
  display: inline-block;
}

project-details .block__controls project-action,
project-details .stable-form-buttons project-action,
project-details-edit .block__controls project-action,
project-details-edit .stable-form-buttons project-action  {
  float: right;
}
