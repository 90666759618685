/* copied from annotation tool's main.scss */
.annotation-window {

  vml\:* {
    behavior: url(#default#VML);display:inline-block
  }

  overflow: auto;
  text-align: center;

  .bottom-content {
    clear: both;
    display: inline-block;
    width: 100%;
  }

  .image-scroll {
    float: left;
    margin: 0 0 0 2%;
    overflow: auto;
    width: 71%;
  }

  .image-wrapper {
    -moz-box-shadow: 20px 0 40px rgba(0,0,0,.2);
    box-shadow: 20px 0 40px rgba(0,0,0,.2);
    box-shadow: 20px 0 40px rgba(0,0,0,.2);

    display: inline-block;
    position: relative;
    background-size: cover;

    .annotation, .comment-box {
      position: absolute;
    }

    .annotation {
      cursor: pointer;
      .comment{
        padding: 0.3em;
        position: relative;
        z-index: 10;
        text-align: left;
      }
        .comment-hidden {
          background-color: white;
          display: none;
          font-size: 0.8em;
          margin-left: 0.3em;
          padding: 0.5em;
          vertical-align: top;
        }
        .comment-graphic {
          width: 25px;
          height: 25px;
        }

      &.open {
        z-index: 200;
      }
      .isnew{
        .comment-hidden{
          display: block;
        }
      }
      &.active {
        z-index: 100;
      }
    }
  }
    .click-div {
      bottom: 0;
      display: none;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5000;
      &.active {
        display: block;
        z-index: 9999;
      }
    }

    .annotation {
      &.open{
        .annotation-inside {
          .btn--delete, .btn--edit {
            /*display: inline-block;*/
          }
          .annotation--content {
            /*display: block;*/
          }
        }
      }
      .annotation-inside {
        .btn {
          cursor: pointer;
        }
        .btn {
          font-size: 0.7em;
          padding: 0.5em 1em;
        }
        .comment-text {
          max-height: 150px;
          overflow: auto;
          text-align: left;
        }
        .comment-textarea {
          max-width: 100%;
          min-height: 70px;
        }
        .comment-textarea, .btn--done, .btn--cancel{
          display: none;
        }
        &.editing{
          .comment-textarea, .btn--done, .btn--cancel{
            display: inline-block;
          }
          .comment-text, .btn--edit, .btn--delete{
            display: none;
          }
        }
        &:nth-child(n) {
          filter: none;
        }
      }
    }

  .sidebar-drawer {
    background: none repeat scroll 0 0 grey;
    float: right;
    min-height: 100px;
    overflow: auto;
    padding: 1px;
    width: 24%;
    .annotation {
      cursor: pointer;
    }
    .new-info-box, .js-drawer-hidden {
      display: none;
    }
    .open{
      .drawer-open{
        display: none;
      }
    }
    .closed{
      .drawer-close{
        display: none;
      }
    }
  }

    .comment-box {
      left: 0px;
      top: 0px;
      visibility: hidden;
      white-space: nowrap;
        .comment-box--textarea{
          padding: 0.5em;
        }
      &.active {
        visibility: visible;
        z-index: 9000;
      }
    }

  .annotation-canvas {
    z-index: 1;

    &.highlight{
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      opacity: 0.3;
    }

    &.active{
      z-index: 9999;
    }
  }

    .btn {
      background-color: #7f898e;
      cursor: pointer;
      display: inline-block;
      margin: 0.2em;
      padding: 0.2em 0.5em;
      user-select: none;

      &.active {
        /*background: #363a3d;*/
      }
      &:hover{
        /*outline: 2px solid black;*/
      }
      &:active{
        /*background-color: #5c5c5c;*/
      }
    }

      .btn--done {
        background: none repeat scroll 0 0 green;
      }

  &.js-annotation-active {
    .image-wrapper {
      cursor: pointer;
    }
  }

  canvas {
    display: inline-block;
    & div {
      width: 100%;
      height: 100%;
    }
  }

  .pageInput, .zoomInput {
    text-align: center;
    width: 20px;
  }

  .unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .hidden {
    display: none !important;
  }

  .noselect {
     -webkit-touch-callout: none;
     -webkit-user-select: none;
     -khtml-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
  }

  .reselect {
     -webkit-touch-callout: text;
     -webkit-user-select: text;
     -khtml-user-select: text;
     -moz-user-select: text;
     -ms-user-select: text;
     user-select: text;
  }

  .clear {
    clear: both;
  }

  .btn--exit {
    display: none;
  }

  &.read-only{
    .btn--comment, .btn--highlight, .btn--pen, .btn--clearPage,
    .btn--clearAll, .btn--save, .btn--edit, .btn--delete {
      display: none !important;
    }
    .btn--exit {
      display: block !important;
    }
  }

  .save-indicator{
    display: none;
  }

  &.api-saving {
    .btn--save{
      opacity: 0.5;
      cursor: default;
      .not-save-indicator {
        display: none;
      }
      .save-indicator {
        display: inline-block;
      }
    }
  }

  .loader{
    display: none;
      .loader-img {
        display: none;
      }
  }

  &.loading {
    .image-wrapper{
      box-shadow: none;
    }
    .loader {
      display: block;
      .loader-img {
        display: none;
        display: block\9;
      }
    }
  }
}

/* copied from annotation-tool's vzw.scss */
$dark-grey: #363739;
$med-grey: #525357;
$light-grey: #cbcbcb;
$near-white-light: #fefefe;
$near-white-dark: #e8eaea;
$blue-link: #2e67b2;

$dark-grey-text: #232324;
$light-grey-text: #7f7f7f;

$brand-clr: #ec1b23;

$grey-border: #BABAB9;

$comment-yellow: #fff779;

// Box-Shadows
@mixin box-shadow($shadow...) {
    -moz-box-shadow:    $shadow;
    -webkit-box-shadow: $shadow;
    box-shadow:         $shadow;
}
// Simple Vertical Linear Background Gradient
@mixin linear-gradient($color1, $color2, $fallback: $color1) {
    background-color: $fallback; /* The Fallback */
    background-image: -webkit-gradient(linear, left top, left bottom, from($color1), to($color2));
    background-image: -webkit-linear-gradient(top, $color1, $color2);
    background-image:    -moz-linear-gradient(top, $color1, $color2);
    background-image:      -o-linear-gradient(top, $color1, $color2);
    background-image:         linear-gradient(to bottom, $color1, $color2);
}


.annotation-window {
  font-family: arial;

  .btn {
    // background-color: $near-white-dark;
    // background: linear-gradient(top,  $near-white-light,  $near-white-dark);
    background-color: #fff;
    // border-radius: 0.4em;
    border-radius: 14px;
    // border: 1px solid $grey-border;
    border: 1px solid #000;
    color: $light-grey-text;
    font-size: 0.8em;
    font-weight: bold;
    padding: 0.8em 1.5em;

    &:hover {
        outline: none; 
    }
  }

  > div {
      margin: 1em;
  }

  .top-content {
      text-align: right;

      & > div {
        display: block;
      }

      .top-content--left {
        .title-info {
          float: left;
          font-size: 2em;
      }
      .user-info {
          display: none;
      }

        display: inline;
        text-align: left;
        width: auto;
    }
  }

    .save-buttons {
        text-align: right;
    }

  .annotation-tool-shell{
    background-color: $dark-grey;
  }

    .toolbar{
      // background-color: $med-grey;
      @include box-shadow(inset 0 0 5px rgba(0,0,0,.5));
      color: $light-grey;
      outline-offset: -0.1em;
      outline: 0.1em solid $med-grey;
      padding: 1em 0.5em;
        @include linear-gradient(#525356, #3d3e40, #525356);

      div {
          vertical-align: middle;
      }
    }

      .toolbarLeft, .toolbarRight {
        p {
          display: inline;
        }
        div, input {
          margin-left: 0.5em;
            margin-right: 0.5em;
          }

          .spacer {
            display: inline;
        }
      }

      .toolbarLeft {
          float: left;
      }

      .toolbarRight {
          float: right;
            font-size: 0.8em;
      }

      .btn--tool {
        background: none;
        background-color: rgba(0, 0, 0, 0);
          border: medium none;
          height: 26px;
          overflow: hidden;
          padding: 0.2em;
          width: 26px;
          vertical-align: middle;
          > img {
            max-width: 100%;
            max-height: 100%;
        }
        &.active {
          background-color: $dark-grey;
        }
      }

      .btn--brand {
        // @include linear-gradient(#ec1b23, #bf1c29, #ec1b23);
        color: #fff;
        // background-color: $brand-clr;
        background-color: #000;
        // border: 1px solid;
        // border-color: #c15748;
      }

      .btn--silent {
        color: $blue-link;
        background-color: transparent;
        background-image: none;
        border: none;
        text-transform: uppercase;
      }

      .btn--inactive {
          background-color: $med-grey;
          border-color: $dark-grey;
          cursor: default;

          &.btn--tool {
          background: none;
          opacity: 0.3;
          }
      }

    .image-wrapper{
      .loader {
          padding: 2em;
      }
        svg path, svg rect{
          fill: $brand-clr;
        }
        .annotation {
        &.active {
            outline: 1px solid $brand-clr;
            box-shadow: 0 0 30px #ffffff inset;
          }
          .annotation-inside {
            .btn--delete, .btn--edit {
              display: none;
            }
          }
          
          .comment-hidden {
            background-color: $comment-yellow;
            border-radius: 0.3em;

            .comment-text {
              border-radius: 0.3em;
              padding: 0.7em;
              background-color: #ffffff;
            }

            .btn {
              float: right;
              margin-top: 0.5em;
            }

            .comment-author {
              div {
                display: inline-block;
                margin-bottom: 0.5em;
            }
            &:first-child {
              padding-right: 1em;
            }
          }
        }
        }
    }

    .sidebar-drawer {
      @include box-shadow(-10px 0 30px rgba(0,0,0,.25));
      color: $light-grey;
      padding: 0 0.1em 0.1em;
        background: none repeat scroll 0 0 $med-grey;
        float: right;
        width: 24%;

        .annotation, .annotation-drawer-top {
          padding: 0.5em;
      }

      .annotation-drawer-top {
        @include box-shadow(inset 0 0 10px rgba(0,0,0,.25));
        background-color: $dark-grey;
      }

        .annotation {
          @include box-shadow(inset 0 1px 3px rgba(0,0,0,.15));
          @include linear-gradient(#505154, #47484b, #505154);
          border: 0.1em solid $dark-grey;
          border-top: 0;
          position: relative;
          padding-left: 45px;
          text-align: right;
          &.active, &.open {
            @include box-shadow(inset 0px 0px 12px rgba(0, 0, 0, .5));
            @include linear-gradient(#cfcfcf, #b4b4b4, #cfcfcf);

            .annotation--drawer-top {
              color: $dark-grey;
            }
            color: $dark-grey;
            background-color: $light-grey;
            background-image: none;
            outline: none;
          }

          .type-graphic {
            height: 25px;
            left: 10px;
            position: absolute;
            top: 10px;
            width: 25px;
        }
        }

        .annotation--drawer-top {
          color: $light-grey-text;
          font-size: 0.8em;
          font-weight: bold;
          padding-bottom: 0.5em;
          text-align: left;
          .toggle {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 25px;
            img {
              max-height: 25px;
              max-width: 25px;
            }
        }
      }

      .comment-text, .comment-textarea {
        margin-bottom: 0.5em;
          text-align: left;
      }
    }

    .image-wrapper, .sidebar-drawer{
      .annotation{
          .btn--edit, .btn--delete {
            background-color: transparent;
            height: 18px;
            margin: 0 0 0 1em;
            width: 18px;
          padding: 0;
          img {
            max-width: 18px;
            max-height: 18px;
          }
          }
      }
    }

  .bottom-spacer {
    background-color: $med-grey;
    height: 0.5em;
    border-top: 0.1em solid;
    border-bottom: 0.3em solid;
    border-color: $dark-grey;

    border-bottom: none;
    position: relative;
    top: -7px;
  }
}