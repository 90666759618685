//Used to clear floats
.group {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.hidden {
    display: none;
}

.wrap-text {
    word-wrap: break-word
}

.right-align {
    text-align: right;
}

.space-above {
    margin-top: 0.3rem;
}

.space-left {
    margin-left: 1rem;
}

.space-below {
    margin-bottom: 0.5rem;
}

//IE only styles
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .not-ie {
        display: none;
   }
}
