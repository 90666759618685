.icon {
    display: inline-block;
    vertical-align: middle;
    
    svg {
        display: block;
    }
}

.user-icon {
    background-color: $black;
    border-radius: 100%;
    color: $white;
    font-size: calcRem(24px);
    height: calcRem(54px);
    line-height: 2.425;
    text-align: center;
    text-transform: uppercase;
    width: calcRem(54px);
}
