.modal-bg {
  background-color: rgba($black, .85);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z + 998;
}

.modal-close {
  right: calcRem(20px);
  top: calcRem(20px);
  position: absolute;
  height: calcRem(25px);
  width: calcRem(25px);
  z-index: 1000;

  svg {
    display: block;
    height: inherit;
    width: inherit;
  }
}

.modal {
  @include centerer;
  background-color: $white;
  max-width: calcRem(1000px);
  padding-bottom: calcRem(60px);
  padding-left: calcRem(60px);
  padding-right: calcRem(60px);
  padding-top: calcRem(60px);
  position: fixed;
  top: 50%;
  width: 90%;
  z-index: $z + 999;
  text-align: left;

  //prompt modal
  .file-card & {
    max-width: 560px;
    text-align: left;
  }

  textarea.form-field {
    max-height: 80px
  }
}

.static-modal {
  @extend .modal;
  position: 0rem;
  left: auto;
  top: auto;
  transform: none;
}

.modal-title {
  color: $black;
  font-size: 2rem;
  line-height: 0.953125;
}

.modal-body {
  .modal-header + &,
  .modal-controls + & {
    margin-top: calcRem(40px);
  }
}

.modal-controls {

  .modal-header + &,
  .modal-body + & {
    margin-top: calcRem(40px);
  }

  &.button-group {
    margin-bottom: calcRem(-8px);
  }
}

.link.action-modal, .project-action .link {
  display: block;
  margin-bottom: 1rem;
  position: relative;
  text-align: left;
}

.modal-header {
  border-bottom: 3px solid $black;
}

.modal-asset-detail {
  p {
    margin-bottom: 0.125rem;
  }

  & + & {
    margin-top: 1.25rem;
  }
}

.modal--basic {
  padding-left: calcRem(42px);
  padding-right: calcRem(42px);
  max-width: calcRem(564px);
}

.modal--basic .modal-header {
  border: none;
}