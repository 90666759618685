.line {
    background-color: $grey1;
    border: 0;
    height: calcRem(1px);
    margin-bottom: calcRem(10px);
    margin-top: 0;
}

.bar {
    background-color: $black;
    border: 0;
    height: calcRem(4px);
    margin-bottom: calcRem(20px);
    margin-top: 0;
}

.foobar {
    @extend .bar;
    margin-bottom: calcRem(30px);
}

.message-bar {
  color: #ffffff;
  bottom: 0;
  left: 0;
  padding: 1em;
  position: fixed;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.message-bar-error {
  background-color: #c82121;
}

.message-bar-success {
  background-color: #66bb6a;
}

.toast-container {

}