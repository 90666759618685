a {
	color: $black;
}

.link {
	text-decoration: underline;
	color: $black;
}

.link--bold {
	text-decoration: underline;
	font-family: $font-text-bold;
}

.caret {
	font-family: $font-text-bold;
	text-decoration: underline;

	svg {
		height: calcRem(12px);
		margin-left: calcRem(5px);
	}
}

.caret--left svg{
	margin-left: 0;
	margin-right: calcRem(5px);
}

.white-caret {
	@extend .caret;
	color: $white;

	svg {
		fill: $white;
	}
}

.large-caret {
	@extend .caret;
	font-size: 0.875rem;

	svg {
		height: calcRem(18px);
		width: calcRem(16px);
	}
}