.filter {
  background-color: $monarch-grey;
  padding-top: 11px;
  height: 100%;

  .revealer-open {
    font-size: calcRem(18px);
  }

}

.filter__header {}

.filter__body {
  color: $black;
  padding: calcRem(20px);

  .filter__header + & {
    margin-top: calcRem(16px);
  }
}

.filter__grp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter__text {
  font-size: calcRem(24px);
  font-weight: 700;
  font-family: $font-disp-bold;
}

.filter__link {
  color: inherit;
  float: right;
  font-size: calcRem(12px);
  padding-top: 8px;
  font-family: $font-text-reg;
}

.filter__form {
  clear: both;

  .filter__link + & {
    margin-top: calcRem(30px);
  }
}

.filter__group {
  border-top: 1px solid;
  border-color: $grey2;
  margin-top: calcRem(10px);

  &:first-of-type {
    border-top: unset;
  }

  &:last-of-type {
    border-bottom: 1px solid;
    border-color: $grey2;
    padding-bottom: calcRem(10px);
  }
}

.filter__checkbox {
  border-color: $white;
}
