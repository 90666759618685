.box {
  background-color: $grey1;
  padding: calcRem(20px);
}

.white-box {
  background-color: $white;
  border: 1px solid;
  border-color: $black;
}
