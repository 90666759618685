all-projects {

  .status {
    font-family: $font-text-bold;
  }
}

.template-builder {
  display: flex;
  justify-content: space-between;
}

.preview__container {
  width: 50%;
  position: relative;
  align-self: start;
}

.preview__img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  position: relative;
}

.preview__loading-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  animation: loadPreview 1s;

  .template-loader {
    border: 0.25rem solid $white;
    border-radius: 50%;
    border-top: 0.25rem solid transparent;
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loadPreview {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.template-builder__form {
  width: 45%;
  padding: 2rem;
  border: 1px solid $grey1;
  display: flex;
  flex-direction: column;

  .template-builder__section-name {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }

  h4 {
    font-family: $font-text-reg;
    color: $grey2;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}

.template-builder__form-controls {
  align-self: flex-end;
  margin-top: 2.5rem;
}

.template-builder-header {
  margin-bottom: 2rem;
}

.template-builder__project-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
