//Work in progress
//Going to need lots of logical thinking

.tooltip-open {
  cursor: pointer;
}

.tooltip {
  background-color: $white;
  border-color: $black;
  border: 1px solid;
  left: 0;
  max-height: calcRem(290px);
  overflow-y: scroll;
  padding: calcRem(20px);
  position: absolute;
  top: 0;
  width: calcRem(282px);
  z-index: 9;

  // width: 90%;
  // max-width: calcRem(280px);
  // pointer-events: none;
}

.tooltip-close {
  position: absolute;
  right: calcRem(10px);
  top: calcRem(10px);
  height: calcRem(14px);
  width: calcRem(14px);

  svg {
    display: block;
    height: inherit;
    width: inherit;
  }
}

.tooltip-info {
  font-family: $font-text-reg;

  //for listings
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.tooltip-basic {
  display: block;
  padding: calcRem(8px) calcRem(12px);
  color: $white;
  background: $black;
  font-family: $font-text-bold;
  font-size: calcRem(12px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  white-space: nowrap;
}