// .dropdown-wrapper {
//   font-size: calcRem(14px);
//   height: 40px;
//   position: relative;
// }

// .dropdown-select {
//   width: 100%;
// }

// .dropdown {
//   background-color: $white;
//   border: 1px solid;
//   border-color: $grey1;
//   border-bottom-color: $black;
//   cursor: pointer;
//   left: 0;
//   margin-bottom: 0;
//   overflow-x: hidden;
//   overflow-y: auto;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   // z-index: 9000;

//   &.is-active {
//     border-color: $black;
//   }
// }

// .dropdown__item {
//   border-left: 4px solid;
//   border-left-color: transparent;
//   line-height: 1.45;
//   padding: calcRem(10px);

//   .dropdown.is-active & + & {
//     border-top: 1px solid;
//     border-top-color: $grey1;
//   }

//   &.is-hidden {
//     display: none;
//   }

//   &.is-active {
//     display: block;
//   }

//   .dropdown.is-active &.is-active,
//   .dropdown.is-active &.is-active:hover {
//     border-left-color: $red;
//   }

//   .dropdown.is-active &:hover {
//     border-left-color: $black;
//   }
// }

// .dropdown-icon {
//   @include centerer(false,true);
//   right: calcRem(10px);
//   height: calcRem(18px);
//   width: calcRem(18px);

//   svg {
//     display: block;
//     fill: $black;
//     height: inherit;
//     width: inherit;
//   }
// }




















//sortby dropdown label
.dropdown-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 10em;
  min-height: 24px;

  &.dropdown-inline {
    width: auto;
    min-width: 155px;
    display: inline-block;
    vertical-align: middle;
  }

  &.dropdown-inline--bottom {
    width: auto;
    display: inline-block;
    vertical-align: bottom;
  }
}

.dropdown {
  background-color: $white;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin: 0;
  // padding: 6px 0px 8px;
  padding: calcRem(9px);
  list-style: none;
  border: 1px solid #ccc;
  border-bottom: 1px solid $black;
  min-width: 10em;
  width: 100%;
  text-align: left;
  font-size: 14px;

  &:after {
    display: inline-block;
    content: '';
    display: block;
    height: 10px;
    margin-top: -5px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: rotate(90deg);
    width: 8px;
  }

  &.is-active {
    position: absolute;
    left: 0;
    top: 0;
    // margin-top: -7px;
    z-index: 9000;
    overflow-x:hidden;
    overflow-y: auto;
    border: 1px solid black;
    max-height: 20em;
    padding: 0;

    &:after {
      display: none;
    }
  }
}

.dropdown__item {
  display: block;
  opacity: 1;
  text-decoration: none;
  position: relative;
  z-index: 2;
  border-left: 4px solid transparent;
  // padding-left: .75em;

  a {
    text-decoration: none;
    color: inherit;
  }

  &.is-active {
    opacity: 1;
  }

  &.is-hidden {
    display: none;
  }

  .dropdown.is-active & + & {
    border-top: 1px solid;
    border-top-color: $grey1;
  }
}

.dropdown.is-active > .dropdown__item {
  padding: calcRem(9px);
  // padding: 1em;
// border-bottom: 1px solid $gray2;

&.is-active {
  border-left-color: #cd040b;
  &:hover {
// background-color: inherit;
border-left-color: #cd040b;
}

&:before {
  display: inline-block;
// font-family: "icons-common";
font-size: 16px;
//content: '\e710';
// content: '\ea10';
position: absolute;
top: 0;
left: 0;
padding: 1.1em 1.5em 1em 1em;
border-left-color: #cd040b;

// color: $black;
}
}

&:hover {
// background-color: $gray1;
border-left-color: $black;
}
}






.dropdown-icon {
  @include centerer(false,true);
  right: calcRem(10px);
  width: calcRem(14px);
  height: calcRem(10px);

  svg {
    height: inherit;
    width: inherit;
  }
}
