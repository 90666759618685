.table {
    display: table;
    width: 100%;
    text-align: left;

    p {
        margin-bottom: 0;
    }
}

.simple-table {
    @extend .table;
    font-size: calcRem(12px);
}

.simple-table-wrapper {
    border: 1px solid;
    border-color: $grey1;
    height: calcRem(148px);
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        background-color: $white;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: rgba(0,0,0,.5);
    }
}

.thead {
    display: table-header-group;
    font-family: $font-text-bold;
    background-color: $black;
    color: $white;
    
    .tr {
        background-color: $black;
    }

    span {
        cursor: pointer;
    }
}

.tbody {
    display: table-row-group;

    .simple-table & {}
}

.tr {
    background-color: $white;
    display: table-row;

    .tbody &:nth-child(odd) {
        background-color: $lite;
    }

    .thead ~ .tbody &:nth-child(even) {
        background-color: $lite;
    }

    .thead ~ .tbody &:nth-child(odd) {
        background-color: initial;
    }
}

.td {
  display: table-cell;
  padding: calcRem(16px);

  .truncate-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .simple-table & {
      padding: calcRem(10px);
  }

  .inline-list {
      margin-bottom: 0;
      padding-left: 0;
  }
  .inline-list li + li {
      margin-left: calcRem(4px);
  }

  //Vertical align only in the thead
  .thead & {
      vertical-align: middle;
  }

  &--sort {
    cursor: pointer;
    position: relative;
    padding-right: calcRem(32px);

    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 1rem;
      height: 1rem;
      width: 1rem;
      top: 1.3rem;
      fill: $white;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url('../images/icon-caret-up-white.svg');
      transform: rotate(90deg);
    }

    &.sortup {
      &:after {
        background-image: url('../images/icon-caret-up-white.svg');
        transform: rotate(0deg);
      }
    }
    &.sortdown {
      &:after {
        background-image: url('../images/icon-caret-down-white.svg');
        transform: rotate(0deg);
      }
    }
  }
}


