body {
    color: $black;
    font-size: calcRem(14px);
    font-family: $font-text-reg;
    line-height: 1.45;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $font-disp-bold;
    line-height: 0.953125;
    margin-bottom: 1rem;
}

h1 {
    font-size: calcRem(64px);
}

h2 {
    font-size: calcRem(32px);
}

h3 {
    font-size: calcRem(24px);
}

h4 {
    font-size: calcRem(20px);
}

h5 {
    font-size: calcRem(18px);
}

h6 {
    font-size: calcRem(14px);
    font-family: $font-text-bold;
    margin-bottom: 1rem;
}

p,
ul,
ol {
    margin-bottom: 1rem;
}

ul {
    list-style-type: disc;
    padding-left: calcRem(18px);
}

ol {
    list-style-type: decimal;
}

a {
    text-decoration: none;
}

strong {
    font-family: $font-text-bold;
}

em {
    font-family: $font-text-italic;
}

small {
    font-size: 80%;
}

i {
    font-style: normal;
}

sup,
sub {
    font-size: 50%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.525em;
    font-size: 0.65em;
}

sub {
    bottom: -.3625em;
}