.asset-single {
  cursor: pointer;
  display: block;
  max-width: 185px;
  position: relative;

  /*.result__img {
    max-width: 100%;
    max-height: 150px;
  }*/

  .result__img {
    width: 150px;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .asset-link {
    font-size: .9em;
    margin-bottom: 0;
    margin-top: 0.5em;
  }

  .assettype-label {
    color: $grey2;
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }

  .file-indicators {
    background: $white;
    max-height: 0px;
    position: absolute;
    top: 100%;
  }
}

.asset__img {
  width: 60px;
  height: 60px;
  background-size: cover;
}

.asset-modal-img {
  max-height: 45vh;
}