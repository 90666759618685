.yeap {
    color: $green;
}

.maybe {
    color: $orange;
}

.nope {
    color: $red;
}
