.site-footer {
    background-color: $lite;
    font-size: 12px;
    padding-bottom: 100px;
    padding-top: 50px;
}

.site-legal {
    margin-bottom: 0;
}
