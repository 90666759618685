.toast-container {
  position: fixed;
  z-index: 999999;
  
  /*overrides*/
}

.toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  color: #ffffff;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.toast {
  background-color: $blue;
}

.toast-success {
  background-color: $green;
}

.toast-error {
  background-color: $orange;
}

.toast-info {
  background-color: $blue;
}

.toast-warning {
  background-color: $yellow;
}

.toast-top-right {
  top: 60px;
  right: 12px;
}

.toast-message {
  font-size: calcRem(16px);
  font-family: $font-text-reg;
}
