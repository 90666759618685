//Text
@font-face{
    font-family: "NeueHaasGroteskText W01";
    src:url("../fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix");
    src:url("../fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix") format("eot"),
        url("../fonts/a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),
        url("../fonts/bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff"),
        url("../fonts/53812a68-b352-4951-b19c-fe964db7ffe2.ttf") format("truetype"),
        url("../fonts/389e288e-637b-44b2-9b5a-4ecec3ae8e2c.svg#389e288e-637b-44b2-9b5a-4ecec3ae8e2c") format("svg");
}

//Text Italics
@font-face{
    font-family: "NHaasGroteskTXW01-56It";
    src:url("../fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix");
    src:url("../fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix") format("eot"),
        url("../fonts/dc9df9ed-36b9-4522-8e57-1a899ed2c224.woff2") format("woff2"),
        url("../fonts/ff571a3a-fb16-42b1-a691-23d8955aa35e.woff") format("woff"),
        url("../fonts/4e756bdf-4269-4158-aad4-70a09c5eed5c.ttf") format("truetype"),
        url("../fonts/91554ebe-051b-4fa7-bf6e-ac7ed5d0d107.svg#91554ebe-051b-4fa7-bf6e-ac7ed5d0d107") format("svg");
}

//Text Bold
@font-face{
    font-family: "NHaasGroteskTXW01-75Bd";
    src:url("../fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix");
    src:url("../fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix") format("eot"),
        url("../fonts/d13fb250-6b64-4d97-85df-51fc6625a891.woff2") format("woff2"),
        url("../fonts/60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff") format("woff"),
        url("../fonts/dda121ff-e230-440f-83fb-40aefbd6e09a.ttf") format("truetype"),
        url("../fonts/c98782d3-8599-4314-b717-118a629a3aa4.svg#c98782d3-8599-4314-b717-118a629a3aa4") format("svg");
}

//Text Bold Italics
@font-face{
    font-family: "NHaasGroteskTXW01-76BdI";
    src:url("../fonts/1800a121-4983-4f47-9289-a1cd0876ef3e.eot?#iefix");
    src:url("../fonts/1800a121-4983-4f47-9289-a1cd0876ef3e.eot?#iefix") format("eot"),
        url("../fonts/d1fbf511-d681-4002-b57e-cabb331b3b2e.woff2") format("woff2"),
        url("../fonts/135bdd95-f711-4095-8be6-fce6d3f9ef54.woff") format("woff"),
        url("../fonts/5d166d29-ec50-4ded-aa67-9ee9504d6fb2.ttf") format("truetype"),
        url("../fonts/a2b3b90c-2050-4961-95d2-4751f913a101.svg#a2b3b90c-2050-4961-95d2-4751f913a101") format("svg");
}





//Display
@font-face{
    font-family: "NeueHaasGroteskDisp W01";
    src:url("../fonts/c34970a0-5fd3-4c92-b10d-b8dbd145f0e6.eot?#iefix");
    src:url("../fonts/c34970a0-5fd3-4c92-b10d-b8dbd145f0e6.eot?#iefix") format("eot"),
        url("../fonts/1d2142cb-3e68-48df-b188-f1ac45a47a8b.woff2") format("woff2"),
        url("../fonts/9dc6c76b-0260-4292-af1d-0bc9eecbded2.woff") format("woff"),
        url("../fonts/ed18aa48-557e-4d1f-a53c-58399a7c1bc4.ttf") format("truetype"),
        url("../fonts/5b9068b3-d518-4b0a-a5a2-1aa25714df22.svg#5b9068b3-d518-4b0a-a5a2-1aa25714df22") format("svg");
}
//Display Italics
@font-face{
    font-family: "NHaasGroteskDSW01-56It";
    src:url("../fonts/016417eb-25bd-4b80-a60d-2dacbb7f648b.eot?#iefix");
    src:url("../fonts/016417eb-25bd-4b80-a60d-2dacbb7f648b.eot?#iefix") format("eot"),
        url("../fonts/a28c06ea-8829-467a-a7be-4ffdfba4247b.woff2") format("woff2"),
        url("../fonts/3293834c-c7fe-4d69-a914-f94198711fe4.woff") format("woff"),
        url("../fonts/65b936e2-311a-4b71-bf88-b03362853c0f.ttf") format("truetype"),
        url("../fonts/8ab5cb54-50ea-4912-8521-79357a3b8131.svg#8ab5cb54-50ea-4912-8521-79357a3b8131") format("svg");
}
//Display Bold
@font-face{
    font-family: "NHaasGroteskDSW01-75Bd";
    src:url("../fonts/ed82538c-6090-4c05-ac72-c636496df8de.eot?#iefix");
    src:url("../fonts/ed82538c-6090-4c05-ac72-c636496df8de.eot?#iefix") format("eot"),
        url("../fonts/c24b7456-b9fe-40ab-94af-ba8d3025fada.woff2") format("woff2"),
        url("../fonts/da47ecd2-feea-403e-b247-9f8f5bb5157b.woff") format("woff"),
        url("../fonts/0deba34f-9242-462b-a359-74e95714f821.ttf") format("truetype"),
        url("../fonts/31c5f190-f4d8-436b-99bf-0561dd448586.svg#31c5f190-f4d8-436b-99bf-0561dd448586") format("svg");
}
//Display Bold Italics
@font-face{
    font-family: "NHaasGroteskDSW01-76BdI";
    src:url("../fonts/9a8c6e7e-602d-4aa3-9bad-e3571ebefc0e.eot?#iefix");
    src:url("../fonts/9a8c6e7e-602d-4aa3-9bad-e3571ebefc0e.eot?#iefix") format("eot"),
        url("../fonts/e050deac-c053-470f-a815-635994435764.woff2") format("woff2"),
        url("../fonts/abd711ec-4ebd-4bb6-9344-bb69c3e56d7f.woff") format("woff"),
        url("../fonts/997d9611-90a1-4327-ab8a-c9ca357dbc5d.ttf") format("truetype"),
        url("../fonts/85500253-698e-46b3-847b-fc375e5bd4d2.svg#85500253-698e-46b3-847b-fc375e5bd4d2") format("svg");
}
